.Terms h2 {
  font-size: 1.3em;
}
.Terms h3 {
  font-size: 1.2em;
  color: #555555;
}
.Terms h4 {
  font-size: 1.1em;
  margin-bottom: 0px;
}

.Terms li {
  margin-bottom: 1em;
}

.Terms a {
  color: #1a531b;
}
