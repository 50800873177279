.DistributorDropdown {
  & div[aria-disabled=true],
  & div[aria-disabled=false] {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  & div[aria-disabled=true] span:first-child,
  & div[aria-disabled=false] span:first-child {
    font-size: 11px !important;
  }
}

