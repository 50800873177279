.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.center-self {
  align-self: center;
}

.center-items {
  align-items: center;
}

.center-content {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 10px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.border-box {
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.w-43 {
  width: 43%;
}

.mw-100 {
  max-width: 100%;
}

// Image upload cropper
.cropper-face {
  opacity: 0.2;
  border-radius: 50%;
}
.cropper-modal {
  opacity: 0.7;
}

.bottom {
  bottom: 0;
}

.full-width {
  left: 0;
  right: 0;
}

.global-notification {
  right: 50px;
  position: fixed !important;
  bottom: 50px;
  width: 30%;
  min-width: 275px;
  z-index: 1000;
}
