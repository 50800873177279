html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: $black;

  &:hover,
  &:active {
    color: $green;
  }
}

.link {
  color: $green;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;

  &.not-dt {
    @media (min-width: $breakpoint-l) {
      display: inline-block !important;
    }
  }
  &.not-phone {
    @media (max-width: $breakpoint-xs) {
      display: inline-block !important;
    }
  }
  &.not-medium {
    @media (min-width: $breakpoint-s) {
      display: inline-block !important;
    }
  }
}

.mobile-only {
  display: inline-block;
  @media (min-width: $breakpoint-s) {
    display: none;
  }
}

.s-l-only {
  display: none;
  @media (min-width: $breakpoint-xs) {
    display: inline-block !important;
  }
}

.m-l-only {
  display: none;
  @media (min-width: $breakpoint-s) {
    display: inline-block !important;
  }
}

.dt-only {
  display: none;
  @media (min-width: $breakpoint-l) {
    display: inline-block !important;
  }
}

.clear {
  clear: both;
}

.inline {
  display: inline;
}

.wrap {
  word-wrap: break-word;
  wrap: 65%;
}
