@import 'colors';

$topbar-height-mobile: 80px;
$topbar-height-dt: 100px;

$breakpoint-xxs: 320px;
$breakpoint-xs: 480px;
$breakpoint-s: 768px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1280px;
