@import '../../variables/variables';

.OrderDetails {
  max-width: 580px;
  margin: 14px auto 30px auto;
  position: relative;
  
  @media (min-width: $breakpoint-xs) {
    margin: 36px auto 30px auto;
  }

  div[class*=grid-headers], div[class*=grid-item] {
    justify-content: space-around !important;

    div[class*=column]:nth-child(2) {
      display: none;

      @media (min-width: $breakpoint-xs) {
        display: inline;
      }
    }

    div[class*=column]:nth-child(1), div[class*=column]:nth-child(3), div[class*=column]:nth-child(4) {
      width: 30% !important;

      @media (min-width: $breakpoint-xs) {
        width: 25% !important;
      }
    }

    div[class*=column]:nth-child(2) {
      width: 40% !important;

      @media (min-width: $breakpoint-xs) {
        width: 31.25% !important;
      }
    }

    div[class*=column]:nth-child(3) {
      width: 40% !important;

      @media (min-width: $breakpoint-xs) {
        width: 18.75% !important;
      }
    }
  }

  div[class*=grid-headers] {
    border: 1px solid #BDBDBD;
    background-color: #FAFAFA;
    margin-top: 50px;

    @media (min-width: $breakpoint-xs) {
      margin-top: 0;
    }
  }

  div[class*=message-col] {
    padding: 0 !important;
  }

  div[class*=grid-item] {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    background-color: #F5F5F5;

    @media (min-width: $breakpoint-xs) {
      border-bottom: 1px solid #BDBDBD;
    }
  }

  div[class*=no-padding-bottom] {
    padding-bottom: 0 !important;
  }

  div[class*=mobile-item-number] {
    justify-content: center !important;
    align-items: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 36px;
    border: 1px solid #BDBDBD;
    background-color: #FAFAFA;
    margin-bottom: 0;

    p {
      height: fit-content;
    }

    @media (min-width: $breakpoint-xs) {
      display: none !important;
    }
  }

  div[class*=checkbox-row] {
    justify-content: space-between !important;

    div[class*=column] {
      width: 50% !important;

      @media (min-width: $breakpoint-xs) {
        width: 40% !important;
        justify-content: space-between !important;
      }
    }
  }

  div[class*=order-status] {
    display: none !important;

    @media (min-width: $breakpoint-xs) {
      display: inline !important;
    }
  }

  div[class*=inputs-grid] div[class*=grid-custom-column] {
    padding: 0 !important;
  }

  div[class*=inputs-grid] div[class*=grid-custom-column-padding-left] {
    padding: 0 0 0 15px !important;
  }

  div[class*=row]:not(.checkbox-row) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    @media (min-width: $breakpoint-xs) {
      margin-left: 2.25rem;
      margin-right: 2.25rem;
    }
  }

  div[class*=delivery-information] {
    flex-direction: column !important;
    justify-content: space-between !important;
    gap: 14px;

    div[class*=column] {
      width: 100% !important;

      p[class*=hint] {
        margin-bottom: 10px !important;
      }

      @media (min-width: $breakpoint-xs) {
        width: 44% !important;
      }
    }

    @media (min-width: $breakpoint-xs) {
      flex-direction: row !important;
    }
  }

  div[class*=back-button-col] {
    display: flex !important;
    justify-content: center;
  }

  .OrderSign {
    padding: 0 !important;
  }

  .OrderSignTitle {
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }

  .OrderSignSection {
    margin-top: 24px !important;

    .OrderSignSectionRow {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.OrderDetails > div {
  background-color: #fafafa;
  border-radius: 20px;
  margin: 0 !important;
  padding: 20px 0px !important;
}

.OrderDetails hr {
  border: 0;
  border-top: 2px solid #efefef;
}

.Divider {
  border-top: 3px dashed #efefef !important;
  margin: 0 20px 0;
}

.OrderDetails h2 {
  font-weight: normal;
  font-size: 22px;
  margin-top: 50px !important;

  @media (min-width: $breakpoint-xs) {
    margin-top: 25px !important;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.StatusIcon {
  display: block;
  width: 54px;
  height: 54px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
  left: 50%;
  margin: -25px 0 0 -25px;
  text-align: center;

  img {
    max-width: 100%;
    display: inline-block;
  }

  i {
    font-size: 44px !important;
    margin-right: 0 !important;
  }

  @media (min-width: $breakpoint-xs) {
    width: 90px;
    height: 90px;
    margin: -45px 0 0 -45px;
    top: 7px;
  }
}

.OrderAction {
  margin: 5px !important;
}

.Ui.Button.OrderAction:not(.stock-trigger-button) {
  margin: 5px !important;
  padding: 8px !important;
  min-width: 46% !important;
}

.Logo {
  width: 54px;
  height: 54px;

  @media (min-width: $breakpoint-xs) {
    width: 90px;
    height: 90px;
  }
}