.Home {
  text-align: center;
}

.Greeting {
  font-size: 1.3em;
  color: #4db894;
}

.TaskList {
  margin-top: 30px !important;

  @media (min-width: 768px) {
    margin-left: 1.5rem !important;
  }
}

.Task {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fefefe;
  position: relative;
  border: 1px solid #efefef;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  font-size: 1.3rem;

  .TaskIconImg {
    display: inline-block;
  }

  .TaskIconImgHover {
    display: none;
  }

  &.Done strong,
  &.Done a {
    color: #63c1a1;
  }
}

.Task:hover {
  cursor: pointer;

  .TaskIconImg {
    display: none;
  }

  .TaskIconImgHover {
    display: inline-block;
  }

  &.Done strong,
  &.Done a {
    color: #26a658;
  }
}

.TaskIcon img {
  width: auto;
  height: 45px;
  max-width: 45px;
  display: block;
  margin: 0 auto;
}

.TaskContainer,
.VerticalMobileLine {
  padding: 0 !important;
}

.VerticalMobileLine {
  hr {
    display: none;
  }

  @media (min-width: 768px) {
    align-self: center;
    hr {
      display: block;
      border: 0;
      border-top: 1px solid #ccc;
    }
  }
}

.VL {
  @media (max-width: 767px) {
    border-left: 2px solid green;
    height: 200%;
    position: absolute;
    left: 50%;
    margin: -1rem 0 -1rem -1px;
    top: 0;
  }
}
