@import '../../variables/variables';

.Order {
  margin: 0 auto 20px !important;
  max-width: 580px;
  cursor: pointer;

  &:hover {
    border-color: #ccc;
    box-shadow: 0px 0px 5px #ccc;
  }

  &:last-of-type {
    margin: 0 auto 200px !important;
  }

  div[class*=grid] {
    display: grid;
    padding: 1rem;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;

    @media (min-width: $breakpoint-xs) {
      padding: 0 1rem;
      grid-template-rows: 100%;
      grid-template-columns: auto;
    }
  }
}

.CustomDesktopRow {
  display: none !important;

  @media (min-width: $breakpoint-xs) {
    display: flex !important;
  }
}

.CustomMobileRow {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  gap: 1rem;

  div[class*=column]:not(div[class*=order-information]) {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div[class*=order-information] {
    display: flex !important;
    flex-direction: column;
    gap: 11px;
    margin-bottom: 1rem !important;
  }

  div[class*=order-detail] {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      margin-bottom: 0px;
    }
  }

  div[class*=order-status] {
    p {
      margin: 0 !important;
    }
  }

  @media (min-width: $breakpoint-xs) {
    display: none !important;
  }
}

.Logo {
  border-radius: 20px 0 0 20px;
  padding: 20px 0 !important;
  width: 100% !important;
  display: flex !important;

  img {
    width: 150px !important;
    height: 150px !important;
    flex-grow: unset !important;
    border-radius: 50%;
    margin: auto;
    border: 1px solid #ccc;
  
    @media (min-width: $breakpoint-s) {
      width: 120px;
      height: 120px;
    }
  }

  @media (min-width: $breakpoint-xs) {
    width: 25% !important;
  }
}

.Order p {
  margin-bottom: 10px;
}

.StatusIcon {
  display: inline-block;
  width: 48px;
  height: 48px;

  @media (min-width: $breakpoint-xs) {
    width: 30px;
    height: 30px;
  }

  img {
    max-width: 100%;
  }
}

.OrderDetails {
  width: 100% !important;
  margin-bottom: 1rem !important;

  @media (max-width: $breakpoint-xs) {
    width: 37.5%;
    margin-bottom: 0em !important;

    p > span {
      display: block;
    }
  }
}

.DistributorName {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 0px !important;
  color: #333333;
  font-size: 25px;
  font-weight: 600;

  @media (min-width: $breakpoint-xs) {
    display: none;
  }
}