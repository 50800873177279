.FieldChecked,
.FieldUnchecked {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #979797;
  text-align: center;
  border-radius: 50%;
  margin-left: 5px;
  box-shadow: inset 0 0 4px 0px #979797;
  font-size: 14px;

  @media (min-width: 520px) {
    font-size: 16px;
    height: 36px;
    width: 36px;
    line-height: 36px;
  }
}

.FieldChecked {
  background-color: #006666;
  color: #FFFFFF;
  box-shadow: inset 0 0 4px 0px #293C3C;
}

.Checkbox {
  display: none !important;
}