@import '../../variables/variables';

.Settings-Header {
  font-weight: 400 !important;
  color: #333333 !important;
  margin-top: 36px !important;
  padding-bottom: 6px !important;
  border-bottom: 1px solid #333333 !important;

  &:first-of-type {
    margin-top: 0 !important;
  }
}

.Settings-Custom-Grid {
  width: 100%;
  margin: 0px !important;

  div[class*=row] {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    color: #333333;
    font-weight: 700;

    :nth-child(even),
    div[class*=column]:nth-child(3) {
      font-weight: 300;
    }
  }

  .Email {
    overflow-wrap: break-word; 
  }
}

.Settings-Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    div[class*=logo] {
        width: 100%;
    }
}