
.Auth {
  margin: 0 auto;
  max-width: 480px;

  form[class*=form] {
    display: flex;
    flex-direction: column;

    button {
      align-self: center;
    }
  }
}
