.SplashScreen {
  width: 100%;
  height: 100%;
  background-image: url('../../images/splash_image.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 80% auto;

  @media (min-width: 769px) {
    background-size: auto 50%;
  }
}

.Branding {
  width: 100%;
  position: fixed;
  top: 20%;
}

.Slogan {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  text-align: center;

  @media (min-width: 767px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
}

.Logo img {
  max-width: 50%;
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
}
