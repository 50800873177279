@font-face {
    font-family: BananaGroteskRegular;
    font-weight: 400;
    src: url('../fonts/BananaGrotesk-Regular.otf') format('opentype');
}

@font-face {
    font-family: BananaGroteskSemibold;
    font-weight: 600;
    src: url('../fonts/BananaGrotesk-Semibold.otf') format('opentype');
}

@font-face {
    font-family: BriceRegular;
    src: url("../fonts/BriceRegular.woff") format('woff');
}

@font-face {
    font-family: BriceBlack;
    src: url("../fonts/BriceBlack.woff") format('woff');
}