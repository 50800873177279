@import '../../variables/colors';
.Button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font: inherit;
  height: 34px;
  text-transform: capitalize;
  min-width: 165px;
  border-radius: 5px;
}

.BackButton {
  width: 30% !important;
}

.ConfirmButton {
  width: 50% !important;
}

.Cta {
  color: $white;
}

.LightGreen {
  background-color: $green-lighter !important;
  color: white !important;
}

.Secondary {
  background-color: $green-btn-secondary !important;
  color: $white !important;

  &:hover {
    background-color: $green-lightest !important;
  }
}

.Left {
  border-radius: 0 10px 0 0 !important;
}

.Right {
  border-radius: 10px 0 0 0 !important;
}

.ui.button.Success {
  color: $green-darker;
  background-color: $white;
  border: 2.47px solid $green-darker;

  &:hover {
    color: $white;
    background: $green-darker;
  }

  &:active, &:focus {
    color: $green-darker;
    background-color: $white;
  }
}
