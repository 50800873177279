@import '../../variables/variables';

.CustomRow {

    div[class*=column]:nth-child(1), div[class*=column]:nth-child(3) {
        display: none !important;

        @media (min-width: $breakpoint-xs) {
            display: block !important;
            width: 18.75% !important;
        }
    }

    > div[class*=column]:nth-child(2) {
        width: 100% !important;

        @media (min-width: $breakpoint-xs) {
            display: inline-block !important;
            width: 62.5% !important;
        }
    }
}