@import '../../variables/variables';

.AmountControls {
  padding: 5px 5px 5px 0 !important;
  width: 26px !important;
}
.AmountControls img {
  width: 24px;
  height: 24px;
}

.ActionButton {
  font-size: 13px;
  font-weight: 600;
  color: #979797;
  width: 19px !important;
  height: 19px !important;
  padding: 0 !important;;

  @media (min-width: $breakpoint-xs) {
    
    width: 131px !important;
    height: 33px !important;
  }
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  height: 100%;

  button {
    min-width: 100px;
    
    i {
      color: #8a8a8a !important;
    }

    span {
      margin-left: 5px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    @media (max-width: 480px) {
      span {
        display: none;
      }

      i {
        font-size: 18px;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.MobileActions {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-width: 156px;

  > span {
    height: 39px;
    color: #E0E0E0;
  }

  .MobileActionButton {
    height: 39px;
    display: flex;
    gap: 10px;
    color: #333333;

    i {
      color: #828282;
    }
  }
}

.Archived {
  background-color: #dfdfdf !important;
}

.Locations {
  font-size: 0.8em;
}

.ui.InputsColumn {
  display: flex !important;
  gap: 8px;
  padding: 0 !important;
  width: 100% !important;
  margin: 8px 0px !important;

  div[class*=custom-row] {
    display: grid !important;

    div[class*=distributor-row] {
      grid-row: 1;
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      > div[class*=field] {
        width: 90% !important;

        @media (min-width: $breakpoint-xs) {
          width: 95% !important;
        }
      }
    }

    @media (min-width: $breakpoint-xs) {
      padding-top: 0 !important;
    }
  }

  & ~ & {
    margin-top: 0 !important;
  }
}

.InputsRow {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  > div {
    width: 50% !important;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0px;

    > div {
      width: 100% !important;
    }
  }
}

.AddDistributorContainer {
  display: flex !important;
  flex-direction: column !important;
}

.Divider {
  width: 94%;
  border-top: 1px solid #efefef;
}

.MobileTitleRow p {
  width: 100%;
  color: #3c3c43;
  border-bottom: 1px solid #3c3c43;
  font-size: 17px;
}

.InputsRow > div {
  width: 100% !important;
}

.EditStockItemContainer {
  padding-bottom: 50px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 730px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.StockItemContainer {
  padding-bottom: 50px;
}

.LocationTitle {
  color: #3c3c43;
  padding: 0 0 0 8px !important;
  font-size: 16px;
}

.StockItemGrid {
  padding-top: 0 !important;
  margin-bottom: 30px !important;

  @media (min-width: $breakpoint-s) {
    padding-top: 30px !important;
  }
}

.LocationName {
  margin-bottom: 30px !important;

  @media (min-width: $breakpoint-s) {
    margin-bottom: 0 !important;
  }
}