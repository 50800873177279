@import '../../variables/variables';

.OrderList {
  margin-top: 20px;
  padding-bottom: 50px;

  @media (min-width: $breakpoint-s) {
    padding-bottom: 70px;
  }
}

.LocationSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 576px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.Message {
  max-width: 400px;
  margin: 0 auto 1em !important;
}