@import '../../variables/variables';

.DistributorList {
  max-width: 480px;
  margin: 20px auto 80px auto;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .Button.Green,
    .Button.Red {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}