@import '../../variables/variables';

.ImageUploadContainer {
  display: flex !important;
  justify-content: center;
}

.Distributor-Grid {
  div[class*=distributor-inputs] {
    display: flex;
    flex-direction: column;
    width: 68.75% !important;
    padding: 0 !important;
  }

  div[class*=hour-input] {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div[class*=opening] {
      padding: 0;
    }

    div[class*=opening-day], div[class*=opening-hours-checkbox] {
      width: 10%;
    }

    div[class*=timepicker-containers] {
      width: 35%;

      @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-s) {
        width: 140px;
      }
    }
  }
}

.OpeningHours {
  border: 0px !important;
  margin-top: 0 !important;
  width: 100%;
  text-align: right;

  @media (min-width: $breakpoint-s) {
    float: right;
  }

  div[class*=opening] {
    border-top: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:nth-child(2n) {
      padding: 5px 0px !important;
    }
  }

  div[class*=timepicker-containers] {
    margin-top: 20px;


    @media (min-width: 520px) {
      font-size: 16px;
    }

    &:first-of-type {
      margin-top: 8px;
    }
  }
}

.DistributorForm {
  margin-bottom: 75px;

  @media (min-width: $breakpoint-xs) {
    margin-bottom: 150px;
  }
}

.DistributorForm label {
  color: #ccc !important;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper,
  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Red {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }

  .ButtonWrapperCentered {
    justify-content: center;
  }
}

.OpeningHoursHint {
  display: inline-block;
}

.InputsContainer_FirstColumn {
  width: 40%;

  @media (min-width: $breakpoint-xs) {
    width: 25%;
  }
}

.InputsContainer_SecondColumn {
  display: flex;
  width: 55%;
  justify-content: space-between;
  align-items: baseline;

  @media (min-width: $breakpoint-xs) {
    width: 68%;
  }
}

.Button.ButtonGreenCustom {
  width: 30px;
  height: 100%;
  margin-top: 4px;

  &::before {
    font-size: 24px;
    color: #7ED99F !important;
  }
}

.AddAdditionalHours {
  margin-top: 12px;
  display: none;
  cursor: pointer;
  font-size: 14px;

  &:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media (min-width: 520px) {
    font-size: 16px;
  }
}

.AddAdditionalContact {
  color: #979797;
  margin-top: 12px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 520px) {
    font-size: 16px;
  }
}