@import '../../variables/colors';
@import '../../variables/variables';

.Recipe {
  min-height: 155px;
}

.Title {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  width: max-content;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.Detail {
  padding: 15px 20px;
}

.Detail p {
  margin-bottom: 10px;
}

.BtnContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.RecipeDetail {
  max-width: 580px;
  margin: 0 auto !important;
}

.RecipeDetail > div {
  max-width: 80%;
  margin: 0 auto !important;
}

.RecipeDetail .ButtonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  margin-top: 24px !important;

  .Button.Green {
    font-size: 1.14285714rem;
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  }
}

.RecipeDetail .Button.Green.ViewCombo {
  font-size: 1.14285714rem;
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  margin-top: 14px;
}

.RecipeDetail .ButtonsContainerCentered {
  display: flex;
  justify-content: center;
  gap: 36px;
  margin-top: 24px !important;
  
  .Button.Green {
    font-size: 1.14285714rem;
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
  }
}

.Heading {
  font-size: 14px;
  color: #9a9a9a;
}

.RecipeDetail hr {
  border: 0;
  border-top: 3px dashed #efefef;
}

.Archived {
  background-color: #dfdfdf;
}

.Locations {
  font-size: 0.8em;
}

.LocationLabel {
  color: $grey-light;
  font-size: 13px;
}

.NoteItem {
  margin: 6px 0 2px;

  & + & {
    margin-top: 0;
  }
}

.BatchItem {
  display: flex;
  justify-content: space-between;
}

.Modal-Header {
  font-size: 18px !important;
}

.Modal-Description {
  font-size: 16px !important;
}

.Confirm-Button {
  font-size: 18px !important;
  margin: 0 auto !important;
  &,
  &:link,
  &:visited,
  &:active {
      color: #006666 !important;
      background-color: #FFFFFF !important;
      border: 2px solid #006666 !important;
  }
  &:hover {
      color: #FFFFFF !important;
      background-color: #004d4d !important;
      border: 2px solid #004d4d !important;
  }
}