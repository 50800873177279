@import '../../variables/variables';

.Distributor {
  box-shadow: 0px 0px 5px 2px #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px !important;
  overflow: hidden;
  position: relative;

  .IncompleteTag {
    position: absolute;
    top: 12px;
    right: 24px;
    background-color: #F2711C;
    color: white;
    padding: 4px 8px;
    border-radius: 18px;
    font-weight: 700;
  }
}

.Distributor-Grid {
  display: grid !important;
  grid-template-columns: 100% !important;
  grid-template-rows: auto auto auto !important;
  gap: 14px;

  div[class=row]:not(.distributor-information) {
    display: grid !important;
    padding: 0 !important;
  }

  div[class*=column] {
    width: 100% !important;
    display: flex !important;
    grid-column: 1 / 3;

    p {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }

  div[class*=distributor-logo] {
    grid-row: 1;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
    padding: 0 !important;
    min-width: 170px;

    img {
      width: 150px;
      height: 150px;
      flex-grow: unset !important;
      border-radius: 50%;
      margin: auto;
      border: 1px solid #ccc;
    
      @media (min-width: $breakpoint-s) {
        width: 120px;
        height: 120px;
      }
    }
  }

  div[class*=column]:nth-child(1):not(div[class*=distributor-logo]) {
    grid-row: 2;
    height: 100%;
  }

  div[class*=column]:nth-child(2) {
    grid-row: 2;
  }

  div[class*=distributor-information] {
    display: flex;
    flex-direction: column !important;

    dt {
      width: 30px;
    }
  }

  div[class*=column]:last-child {
    grid-column: 1 / 3;
    justify-content: center;
    gap: 12px;

    button {
      max-width: 148px;
      margin: 10px !important;
    }
  }

  @media (min-width: $breakpoint-xs) {
    grid-template-columns: 30% 70% !important;

    div[class=row] {
      grid-column: 1 / 3;
    }

    div[class=row]:nth-child(2) {
      div[class*=column]:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }
      
      div[class*=column]:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        padding-top: 14px;
      }
    }

    div[class*=column]:last-child {
      justify-content: center;
      gap: 12px;
  
      button {
        max-width: 148px;
        margin: 10px !important;
      }
    }
  }
}

.Name {
  font-size: 1.2rem;
  padding-top: 14px;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.5rem;
  }

  @media (min-width: $breakpoint-s) {
    font-size: 1.5rem;
  }
}

.Name.CustomName {
  padding-top: 50px;
}

.Distributor dl {
  font-size: 1.1rem;
  margin: 0;
  line-height: 1;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.1rem;
  }

  @media (min-width: $breakpoint-s) {
    padding-right: 15px;
    margin: 0 0 15px 0;
  }
}

.Distributor dt,
.Distributor dd {
  float: left;
}

.Distributor dt {
  width: 20%;
  @media (min-width: $breakpoint-s) {
    text-align: center;
  }
}

.Distributor dd {
  width: 245px;
  margin: 0;
  text-align: left;
}

.PhoneIcon {
  transform: rotate(-134deg) !important;
}

.OpeningHours .DayLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin-left: 12px;
  font-size: 1.1rem;
}

.OpeningHours .DayLabel_NoExtraInfo {
  justify-content: flex-start;
  font-size: 1.1rem;
}

.OpenHoursPopUp {
  width: max-content;
  margin-bottom: 4px;
}
