@import '../../variables/variables';

.ModalContainer {
  padding: 0 !important;
  border-radius: 18px !important;

  .Ui.Modal.Content {
    box-shadow: inset 0px -10px 12px #EEE !important;
    padding-top: 0 !important;

    &.AddInfoModal {
      border-radius: 0 0 18px 18px;
    }
  }
}

.ModalHeader {
  border-bottom: none !important;
  padding: 0 !important;
}

.ModalHeaderText {
  padding: 0 10px 12px !important;
  font-size: 20px !important;
  border-bottom: 2px solid #000;
  width: 90%;
  margin: 0 auto 12px;
}

.ModalDescription {
  padding: 0 35px !important;
  font-size: 18px !important;
}

.ModalActions {
  background: #FFFFFF !important;
  border-radius: 0 0 18px 18px !important;

  &.ModalActionsCentered {
    justify-content: center !important;
  }
}

.CancelButton,
.ConfirmButton {
  font-size: 14px !important;
  width: 45% !important;

  @media (min-width: $breakpoint-xs) {
    font-size: 1.28571429rem !important;
  }
}

.ButtonsContainer {
  position: fixed !important;
  box-shadow: none !important;
  padding: 15px 25px !important;

  &.AddInfoModal {
    border-radius: 0 0 18px 18px;
  }

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.Form {
  padding: 30px 20px 60px;
}