/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'variables/variables';
@import 'base/font';
@import 'base/base';
@import 'base/text';
@import 'layout/layout';
@import 'layout/containers';
@import 'navigation/navigation';