@import '../../variables/variables';

.MobileCustomButton {
    display: none !important;

    @media (min-width: $breakpoint-xs) {
        display: inline-block !important;
    }
}

.MobileCustomCheck {
    display: inline-block !important;
    font-size: 14px !important;
    color: #BDBDBD !important;

    @media (min-width: $breakpoint-xs) {
        display: none !important;
    }
}