$white: #fff;
$gb-green: #4fab73;
$green: #26a658;
$green-btn-secondary: #5ff0a6;
$green-lighter: #4db894;
$green-lightest: #63c1a1;
$green-dark: #006666;
$green-darker: #054e34;
$black: #000;
$grey-darker: #333333;
$grey-dark: #4c4c4c;
$grey: #979797;
$grey-light: rgba(60, 60, 67, 0.6);
$grey-lighter: #d9d9d9;
$grey-lightest: #dfdfdf;
$orange-light: #ff795e;
$orange: #ff795e;
$blue: #007AFF;
