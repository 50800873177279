.ProfileImage {
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  position: relative;
  display: flex;
}

.ProfileImage img {
  width: 100%;
}

.AddIcon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-right: 0px !important;
  font-size: 2em !important;
}

@media (min-width: 768px) {
  .ProfileImage {
    height: 170px;
    width: 170px;
  }

  .AddIcon {
    font-size: 2.2em !important;
  }
}

.UploadedImage {
  border-radius: 50%;
}
