@import '../../variables/variables';

.TimePicker {
  box-shadow: inset 0 0 2px 0px #979797;
  width: 42%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 80px;
  position: relative;

  input[class*=time-input] {
    width: 100% !important;
    border: none !important;
    padding: 0.4em !important;
    text-align: center !important;
    color: rgba(0, 0, 0, 0.87) !important;

    &::-webkit-inner-spin-button,
    &::-webkit-outter-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.InputsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TriggerButton {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
}

.GridContainer {
  height: 100% !important;
}

.PopupColumn {
  width: 60px !important;
  height: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
}

.SelectorButton {
  width: 92% !important;
  height: 30px !important;
  min-height: 30px !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px !important;
  background-color: transparent !important;

  &:hover {
    background-color: #26a658 !important;
    color: white !important;
  }
}

.Popup {
  border-radius: 10px !important;
  padding: 20px 20px 5px 20px !important;
  height: 200px !important;
  width: 100px !important;

  @media (min-width: $breakpoint-xs) {
    width: 120px !important;
  }
}
