@import '../../variables/variables';

.TextInput {
  position: absolute;
  top: 9px;
  right: 12px;
}

div[class*=distributor-inputs] div[class*=error] {
  .TextInput {
    top: 46px !important;
    color: #DE6360 !important;
  }
}

.FirstTextInputStock {
  position: absolute;
  top: 8px;
  right: 46px;
}

.TextInputStock {
  position: absolute;
  top: 68px;
  right: 11px;
}

div.Input.FullHeight input {
  height: 100% !important;
  margin-top: 10px !important;

  @media (min-width: $breakpoint-s) {
    margin-top: 0 !important;
  }
}