@import '../../variables/variables';

.OrderDetails {

  div[class*=order-headers] {
    display: flex;
    flex-direction: column-reverse !important;
    gap: 20px;

    div[class*=column] {
      width: 100% !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 16px;

      p {
        text-align: center;
      }
    }

    @media (min-width: $breakpoint-xs) {
      flex-direction: row !important;
      flex-wrap: nowrap !important;
      justify-content: space-between !important;
      align-content: center;
    }
  }
  
  div[class*=all-received-row] {
    display: none !important;
    justify-content: space-between;
    flex-wrap: nowrap !important;

    div[class*=column] {
      width: 100% !important;
      display: flex !important;
      justify-content: center;
    }

    @media (min-width: $breakpoint-xs) {
      display: flex !important;
    }
  }

  div[class*=missing-products-message] {
    margin: 0 0.85rem !important;

    div[class*=column] {
      padding: 0 !important;
    }

    @media (min-width: $breakpoint-xs) {
      margin: 0 3rem !important;
    }
  }

  div[class*=mobile-received-row] {
    display: flex !important;
    justify-content: center;
    margin-top: 1rem;

    div[class*=column] {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
    }

    button {
      width: 132px;
    }

    @media (min-width: $breakpoint-xs) {
      display: none !important;
    }
  }

  div[class*=missing-order-row] {
    justify-content: center !important;

    div[class*=column] {
      width: 193px !important;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
    }
  }

  div[class*=ordered-grid-headers] {
    height: 44px;
    background-color: #FAFAFA;
    border: 1px solid #BDBDBD;
    margin: 0 0.85rem !important;
    padding: 1rem !important;

    div[class*=column] {
      width: 50% !important;
      padding: 0 !important;
    }

    div[class*=column]:nth-child(3) {
      display: none !important;
    }

    @media (min-width: $breakpoint-xs) {
      margin: 0 3rem !important;
    }
  }

  div[class*=delivered-grid-headers] {
    height: 44px;
    background-color: #FAFAFA;
    border: 1px solid #BDBDBD;
    margin: 0 0.85rem !important;
    padding: 1rem !important;

    div[class*=column] {
      width: 50% !important;
      padding: 0 !important;

      @media (min-width: $breakpoint-xs) {
        width: 33% !important;
      }
    }

    div[class*=column]:nth-child(2) {
      display: none !important;

      @media (min-width: $breakpoint-xs) {
        display: inline-block !important;
      }
    }

    @media (min-width: $breakpoint-xs) {
      margin: 0 3rem !important;
    }
  }

  div[class*=body-row-container] {
    background-color: #FAFAFA;
    border-bottom: 1px solid #BDBDBD;
    margin: 0 0.85rem !important;
    padding: 0 !important;

    @media (min-width: $breakpoint-xs) {
      margin: 0 3rem !important;
    }
  }

  div[class*=row-item] {
    width: 100%;
    height: 60px;
    display: flex !important;
    background-color: #F5F5F5;
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    padding-left: 1rem;
    padding-right: 1rem;

    div[class*=column] {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div[class*=field] {
        width: 70%;
        margin: 0;
      }

      p {
        width: 100%;
      }
    }

    div[class*=desktop-item-amount] {
      display: none !important;

      @media (min-width: $breakpoint-xs) {
        display: flex !important;
      }
    }
  }

  div[class*=row-number] {
    width: 100%;
    height: 36px;
    display: flex !important;
    background-color: #FAFAFA;
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    padding-left: 1rem;
    padding-right: 1rem;

    div[class*=column] {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  div[class*=update-botton-row] {
    margin-top: 6px;
    
    div[class*=column] {
      display: flex !important;
      justify-content: center;
      align-items: center;

      button {
        width: 50% !important;
        max-width: 178px !important;
      }
    }
  }

  div[class*=missing-error-message] {
    justify-content: center !important;

    div[class*=column] {
      background-color: #FF3B304D;
      border-radius: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1rem;
      max-width: 400px;
    }
  }

  p[class*=missing-product-text] {
    color: #006666;
    font-weight: 700;
  }
}

.OrderDetails > div {
  background-color: #f5f5f5;
  border-radius: 20px;
  margin: 0 !important;
  padding: 20px 0px !important;
}


.DistributorName {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.InputContainer {
  display: flex;
  place-content: center;
  gap: 8px;

  label {
    height: 50%;
    align-self: center;
  }
}

.ui.button.CustomColor {
  color: #054e34;
  border: 1px solid #ff795e;
  background: #ffffff;

  &:hover {
    color: #ffffff;
    background: #ff795e;
  }
}
