.ControlInput {
  width: 80% !important;
  height: 34px;
  display: flex;
  margin: 8px auto 0;
  justify-content: center;

  div[class*=label] {
    width: 40px;
    padding: 8px !important;
    font-size: 14px !important;
    height: 30px;
    border-radius: 0 20px 20px 0 !important;
  }

  div[class*=input-container] {
    width: 110px;

    input {
      width: 100% !important;
      border-radius: 0px !important;
    }

    input[class*=controls-input] {
      height: 29px !important;
      box-shadow: inset 1px 1px 5px #D4D4D5 !important;
      border-radius: 20px 0 0 20px !important;
    }
  }

  @media (min-width: 300px) {
    div[class*=input-container] {
      width: 30%;
    }
  }

  div[class*=buttons-container] {
    width: 30% !important;
    padding-left: 0;
    padding-top: 4px;

    button.ButtonContainerButton {
      width: 23px !important;
      height: 23px;
      padding: 0 !important;
      border-radius: 50% !important;
    }

    .ButtonContainerButton + .ButtonContainerButton {
      margin-left: 3px !important;
    }

    @media (min-width: 599px) {
      button.ButtonContainerButton {
        width: 30px !important;
        height: 30px;
      }
    }
  }

  @media (min-width: 599px) {
    div[class*=input-container] {
      width: 110px;
    }

    div[class*=buttons-container] {
      padding-top: 0 !important;
    }

    div[class*=buttons-container] {
      width: 100px !important;
      padding-left: 20px;
    }

    div[class*=label] {
      font-size: 13px !important;
    }
  }
}

@media (min-width: 599px) {
  .ControlInput {
    width: 100% !important;
  }
}
