.AccordionContainer {
  .AccordionTitle {
    display: flex;
    width: 82%;
    align-items: center;
    padding-left: 6px;

    h3 {
      font-weight: 300;
      font-size: 1.1rem;
      color: #006666;
    }

    i.Icon {
      color: #5FF0A6;
      width: 10%;
      text-align: right;
    }
  }

  p.Accordion.Item {
    font-size: 1.1rem;
    text-align: left;
    padding-left: 6px;
    font-weight: 300;
  }
}

