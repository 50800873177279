@import '../../variables/variables';

.StockList {
  margin-bottom: 90px;
}

.StockList thead th {
  font-weight: normal !important;
}

.TopInputsGrid {
  margin: 0 !important;

  .TopInputsRow {
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 20px;

    .TopInputsColumn {
      width: 100% !important;

      @media (min-width: $breakpoint-xs) {
        width: 50% !important;
      }
    }

    @media (min-width: $breakpoint-xs) {
      flex-direction: row !important;
      gap: 0px;
    }
  }
}

.MobileHeader {
  display: inline-block !important;

  @media (min-width: $breakpoint-xs) {
    display: none !important;
  }
}

.DesktopHeader {
  display: none !important;

  @media (min-width: $breakpoint-xs) {
    display: inline-block !important;
  }
}

.StockListTable {
  .ButtonsContainer {
    position: fixed !important;
  }

  .ButtonWrapper,
  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;

        &:not(.stock-trigger-button) {
          max-width: 230px;
        }
      }
    }
  }

  .ButtonWrapperCentered {
    justify-content: center;
  }
}

