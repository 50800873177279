.SecurityWarning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: BananaGroteskRegular, 'Assistant', sans-serif;
    height: 100%;
    background-color: #b70000;
    color: #EEEEEE;

    .Italic {
        font-style: italic;
    }

    .IconContainer {
        padding: 0 70px;
    }

    h1 {
        margin: 0;
        font-size: 38px;
    }

    p {
        font-size: 22px;
        margin: 18px 0 0;
        padding-left: 10px;
    }

    .Footer {
        border-top: 2px solid #EEEEEE;
        padding-top: 22px;
    }

    .Content {
        padding: 0 80px 0 10px;
    }

    .Icon {
        font-size: 6em;
        margin: 20px 0 0;

        &::before {
            color: #DDD;  
        }
    }

    .Website,
    .Website:link,
    .Website:visited,
    .Website:hover,
    .Website:active {
        font-style: italic;
        display: block;
        font-size: 23px;
        text-decoration: underline;
        padding: 6px 0;
        color: #D8E40C;
    }
}