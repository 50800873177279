body {
  font-size: 14px;
  font-family: BananaGroteskRegular, 'Assistant', sans-serif;

  @media (min-width: $breakpoint-xs) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: BananaGroteskSemibold, 'Assistant', sans-serif;
}

h1,
.h1 {
  font-size: 30px;
}

h2,
.h2 {
  font-size: 28px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}

.small {
  font-size: 11px;
}
.x-small {
  font-size: 10px;
}
.large {
  font-size: 16px;
}
.x-large {
  font-size: 18px;
}
.xx-large {
  font-size: 20px;
}

@media (min-width: $breakpoint-xs) {
  .small {
    font-size: 14px;
  }
  .x-small {
    font-size: 12px;
  }
  .xx-small {
    font-size: 10px;
  }
  .large {
    font-size: 18px;
  }
  .x-large {
    font-size: 20px;
  }
  .xx-large {
    font-size: 22px;
  }
  .xxx-large {
    font-size: 24px;
  }
}

.bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-justify {
  text-align: justify;
}

.no-wrap {
  white-space: nowrap !important;
}

.hint {
  color: $grey;
}

.form-message {
  margin-bottom: 10px !important;
  width: 100%;
}

h2.ui.dividing.header {
  font-size: 1.2em;
  color: $grey;
  font-weight: 400;
}

.checkbox-prefix {
  display: inline-block;
  font-style: italic;
  margin-right: 10px;
}

.light-font {
  font-weight: normal !important;
}

.green-text {
  color: $gb-green;
}

.green-text.disabled {
  opacity: 60%;
  pointer-events: none;
}

.green-darker-text {
  color: $green-dark;
}

.orange-text {
  color: $orange;
}

.blue-text {
  color: $blue;
}

.grey-darker-text {
  color: $grey-darker;
}

.top-spacing {
  margin-top: 16px;
}
