@import '../../variables/variables';

.Provider-Information {
    form[class*=form], div[class*=current-provider] {
        display: flex;
        flex-direction: column;
    }
}

.Provider-Grid {
    max-width: 520px;
    align-self: center;

    div[class*=column]:nth-child(1), div[class*=column]:nth-child(2), div[class*=required-input] {
        width: 100%;

        @media (min-width: $breakpoint-xs) {
            width: 75%;
        }
    }
}

.Modal-Header {
    font-size: 18px !important;
}

.Modal-Description {
    font-size: 16px !important;
}

.Confirm-Button {
    font-size: 18px !important;
    margin: 0 auto !important;

    &,
    &:link,
    &:visited,
    &:active {
        color: #006666 !important;
        background-color: #FFFFFF !important;
        border: 2px solid #006666 !important;
    }

    &:hover {
        color: #FFFFFF !important;
        background-color: #004d4d !important;
        border: 2px solid #004d4d !important;
    }
}