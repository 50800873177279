@import '../../variables/colors';
@import '../../variables/variables';

.MessageContainer {
  border: none;
  box-shadow: none !important;
  background-color: #4fab731a !important;
  padding: 0.75em !important;

  .content > .header {
    color: $green-darker;
  }

  p {
    color: $grey-darker;
  }

  span {
    color: $gb-green;

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: $breakpoint-xs) {
    padding: 1em 1.5em !important;
  }
}
