@import '../../variables/variables';

.EditStockContainer {
  padding-bottom: 75px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
}
