@import '../../variables/variables';

.Company-Details-Grid {
  width: 100%;
  margin: 0px 0px 40px 0px !important;

  div[class*=row] {
    @media (max-width: $breakpoint-xs) {
        padding-bottom: 0px !important;

        div[class*=column] {
            padding: 0 !important;
        }
    }
  }

  div[class*=row]:nth-child(5), div[class*=row]:nth-child(6) {
    display: grid !important;

    div[class*=column] {
        width: 100%;
    }
  }

  div[class*=row]:nth-child(5) {
    grid-template-columns: 40% 60% !important;

    @media (max-width: $breakpoint-xs) {
      grid-template-rows: auto auto !important;
      gap: 14px 0px;

      div[class*=column]:nth-child(odd) {
        grid-row: 1;
        grid-column: 1 / 3;
      }

      div[class*=column]:nth-child(even) {
        grid-row: 2;
        grid-column: 1 / 3;
      }
    }
    
  }

  div[class*=row]:nth-child(6) {
    grid-template-columns: 100%;
  }
}
