@import '../../variables/colors';
@import '../../variables/variables';

.Locations {
  max-width: 560px;
  margin: 20px auto 0px auto;
  padding-bottom: 50px;

  @media (min-width: $breakpoint-s) {
    padding-bottom: 80px;
  }
}

.Location {
  box-shadow: 0px 0px 5px 2px #ccc;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px !important;
  overflow: hidden;
}

.Location-Grid {
  display: grid !important;
  grid-template-columns: 100% !important;
  grid-template-rows: auto auto auto !important;
  gap: 14px;

  div[class=row]:not(.location-information) {
    display: grid !important;
    padding: 0 !important;
    gap: 20px 0px;
  }

  div[class*=column] {
    width: 100% !important;
    display: flex !important;
    grid-column: 1 / 3;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }

  div[class*=location-logo] {
    grid-row: 1;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
    padding: 0 !important;
    max-width: 100%;

    img {
      width: 150px;
      height: 150px;
      flex-grow: unset !important;
      border-radius: 50%;
      margin: auto;
      border: 1px solid #ccc;
    
      @media (min-width: $breakpoint-s) {
        width: 120px;
        height: 120px;
      }
    }

    @media (min-width: $breakpoint-s) {
      max-width: 100%;
    }
  }

  div[class*=column]:nth-child(1):not(div[class*=location-logo]) {
    grid-row: 2;
    height: 100%;
  }

  div[class*=column]:nth-child(2) {
    grid-row: 2;
  }

  div[class*=location-information] {
    display: flex;
    flex-direction: column !important;
    max-width: 100%;

    div[class*=information-item] {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @media (min-width: $breakpoint-s) {
      max-width: 100%;
    }
  }

  div[class*=column]:last-child {
    grid-column: 1 / 3;
    justify-content: center;
    gap: 12px;

    button {
      max-width: 148px;
      margin: 10px !important;
    }
  }

  @media (min-width: $breakpoint-xs) {
    grid-template-columns: 30% 70% !important;

    div[class=row] {
      grid-column: 1 / 3;
    }

    div[class=row]:nth-child(2) {
      div[class*=column]:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }
      
      div[class*=column]:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        padding-top: 14px;
      }
    }

    div[class*=column]:last-child {
      justify-content: center;
      gap: 12px;
  
      button {
        max-width: 148px;
        margin: 10px !important;
      }
    }
  }
}

.Location {
  padding-bottom: 25px !important;
}

.Inactive {
  background-color: #F2F2F2 !important;
}

.Selected {
  border: solid 3px #6BA878 !important;
}

.ImageUploadContainer {
  display: flex !important;
  justify-content: center;
}

.Name {
  font-size: 1.2rem;
  padding-top: 14px;
  position: relative;

  span.Badge {
    position: absolute;
    color: #204D36;
    background-color: #58DC99;
    right: 10px;
    top: -12px;
    padding: 6px 8px;
    border-radius: 12px;
    font-size: 10px;
    line-height: 10px;

    @media (min-width: $breakpoint-xs) {
      font-size: 12px;
      line-height: 12px;
      padding: 8px 10px;
      right: 26px;
      top: -10px;
    }
  }

  @media (min-width: $breakpoint-xs) {
    font-size: 1.5rem;
  }
}

.PostcodeField {
  @media (min-width: 556px) {
    padding: 10px 10px 0 0;
    display: inline-block;
    width: 30%;
  }
}

.PlaceField {
  @media (min-width: 556px) {
    padding: 10px 0 0 10px;
    display: inline-block;
    width: 70%;
  }
}

.Button {
  flex: unset;
  align-self: flex-end;
}

.LocationsMessage {
  color: #6e6e6e;
}

.DetailsLocation {
  text-decoration: underline;
  cursor: pointer;
  color: #555555;
  font-weight: 600;
}

.EditButtonsContainer {
  position: fixed !important;

  .ButtonWrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.AddLocationContainer,
.EditLocationContainer {
  padding-bottom: 90px;
}

.ButtonsContainer {
  position: fixed !important;

  .ButtonWrapper,
  .ButtonWrapperCentered {
    max-width: 700px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .Button.Green,
    .Button.Orange {
      font-family: BananaGroteskRegular, 'Assistant', sans-serif;
      font-size: 14px;
      width: 45%;
  
      @media (min-width: $breakpoint-xs) {
        font-size: 1.28571429rem;
        width: 100%;
      }
    }
  }
}

.SelectLocationTitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 18px;

  @media (min-width: $breakpoint-s) {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.RadiosContainer {
  display: flex !important;
  padding: 20px 16px !important;

  .RadioInput {
    display: flex !important;
    flex-direction: column;
  }

  .Label {
    padding-left: 42px !important;
    padding-bottom: 8px !important;
  }

  .DropdownContainer {
    padding-left: 42px !important;
  }
}